export const siteConfig = {
  name: 'Casino',
  description: 'Welcome to Casino',
  url: 'https://casino.com/',
  locale: 'en',
  ogImage: '',
  coin_types: [
    {
      id: 1,
      currency_amt: 0,
      currency_name: 'USDT',
    },
    {
      id: 2,
      currency_amt: 0,
      currency_name: 'ABM',
    },
  ],
  provider_slide: [
    {
      id: 1,
      img: '/images/home/slider/providers_1.png',
    },
    {
      id: 2,
      img: '/images/home/slider/providers_2.png',
    },
    {
      id: 3,
      img: '/images/home/slider/providers_3.png',
    },
    {
      id: 4,
      img: '/images/home/slider/providers_4.png',
    },
    {
      id: 5,
      img: '/images/home/slider/providers_5.png',
    },
    {
      id: 6,
      img: '/images/home/slider/providers_6.png',
    },
    {
      id: 7,
      img: '/images/home/slider/providers_7.png',
    },
    {
      id: 8,
      img: '/images/home/slider/providers_1.png',
    },
    {
      id: 9,
      img: '/images/home/slider/providers_2.png',
    },
  ],
};
