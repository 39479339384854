import { React, useState } from 'react';

import './sidebar.css';

import { Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useIsAuthenticated } from 'react-auth-kit';
import Accordion from 'react-bootstrap/Accordion';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { sidebarConfig } from '../../configs/sidebar';
import Icons from '../icons';
import LanguageChanger from './LanguageChanger';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const icons = [
  {
    id: 1,
    icon: <Icons.CasinoCards />,
    name: 'Casino',
  },
];

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Sidebar() {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  const [value, setValue] = useState(0);

  return (
    <div className="main_sidebar scrollY">
      <div className="">
        <Tabs
          className="menu_tab_block"
          aria-label="icon position tabs example"
        >
          {icons.map((list) => {
            return (
              <Tab
                className="menu_tab_btn"
                key={list?.id}
                icon={list?.icon}
                iconPosition="start"
                {...a11yProps(list?.id)}
                label={<span className="icontext">{t(list?.name)}</span>}
              />
            );
          })}
        </Tabs>

        <CustomTabPanel value={value} index={0}>
          <div className="menu_tab_content">
            <Accordion flush className="accordian_block">
              {(isAuthenticated()
                ? sidebarConfig.routesAuth
                : sidebarConfig.routesNoAuth.slice(0,7)
              ).map((items) => (
                <Accordion.Item
                  key={items?.id}
                  className={items?.hasInnerMenu === false ? 'no_menus' : ''}
                  eventKey={items?.id}
                >
                  <Tooltip title={t(items?.hover_msg)} arrow placement="right">
                    {items.disabled ? (
                      <a href="#" className="disabled">
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </a>
                    ) : (
                      <NavLink to={items?.url}>
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </NavLink>
                    )}
                  </Tooltip>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>  
          <div className="menu_tab_content">
            <Accordion flush className="accordian_block">
              {sidebarConfig.routesNoAuth.slice(7,10).map((items) => (  
                <Accordion.Item
                  key={items?.id}
                  className={items?.hasInnerMenu === false ? 'no_menus' : ''}
                  eventKey={items?.id}
                >
                  <Tooltip title={t(items?.hover_msg)} arrow placement="right">
                    {items.disabled ? (
                      <a href="#" className="disabled">
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </a>
                    ) : (
                      <NavLink to={items?.url}>
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </NavLink>
                    )}
                  </Tooltip>
                </Accordion.Item>
              ))}
            </Accordion>
          </div> 
          <div className="menu_tab_content">
            <Accordion flush className="accordian_block">
              {sidebarConfig.routesNoAuth.slice(10,14).map((items) => (  
                <Accordion.Item
                  key={items?.id}
                  className={items?.hasInnerMenu === false ? 'no_menus' : ''}
                  eventKey={items?.id}
                >
                  <Tooltip title={t(items?.hover_msg)} arrow placement="right">
                    {items.disabled ? (
                      <a href="#" className="disabled">
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </a>
                    ) : (
                      <NavLink to={items?.url}>
                        {items?.icon}
                        <span>{t(items?.page_name)}</span>
                      </NavLink>
                    )}
                  </Tooltip>
                </Accordion.Item>
              ))}
            </Accordion>
          </div> 
          <div className="menu_tab_content language_selector">
            <Accordion flush className="accordian_block one">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Icons.LanguageWorld />
                  <span>
                    {t('Language')}: {t(Cookies.get('lang'))}
                  </span>
                </Accordion.Header>
                <AccordionBody>
                  <LanguageChanger />
                </AccordionBody>
              </Accordion.Item>
            </Accordion>
          </div>
        </CustomTabPanel>
      </div>
    </div>
  );
}

export default Sidebar;
