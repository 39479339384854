import { React } from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { footerConfig } from '../../configs/footer';
import Icons from '../icons';

function Footer() {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer_main_wrapper">
      <div className="container">
        <div className="footer_section">
          <div className="footer_wrapper d-none">
            {footerConfig.navigations?.map((item) => {
              return (
                <div key={item.id} className="footer_cols">
                  <h6>{t(item.name)}</h6>
                  <ul>
                    {item?.subnav?.map((nav) => {
                      return (
                        <li key={nav.id}>
                          <Link
                            to={nav.link}
                            target={nav.external ? '_blank' : ''}
                          >
                            {t(nav.name)}
                          </Link>
                          {nav.external ? (
                            <span className="icon" style={{ marginLeft: '3px' }}>
                              <Icons.ExternalLink />
                            </span>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="companies_section d-none">
            <div className="companies_image_wrapper">
              {footerConfig.cards?.map((item) => {
                return (
                  <div key={item?.id} className="companies_image">
                    <img src={item?.icon} alt={item?.name} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="otherlogo_section d-none">
            <div className="otherlogo_image_wrapper">
              {footerConfig.logo?.map((item) => {
                return (
                  <div key={item?.id} className="otherlogo_img">
                    <Link to="#" target="_blank">
                      <img src={item?.icon} alt={item?.name} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="footer_bottom_section">
            <div className="footer_bottom_wrapper">
              <div className="footer_logo_block">
                <Link to="">
                  <img src="/images/footer/footer_logo.png" alt="logo" />
                </Link>
                <h5>{t('About')} Paradise 7</h5>
                <p>{t('FooterPart1')}</p>
              </div>
              <div className="footer_cols">
                <h6>{t('Games')}</h6>
                <ul>
                  <li>
                    <Link to="">{t('Live Casino')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Slots')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Live Dealer')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Crash games')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Roulette')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Blackjack')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Jackpot Games')}</Link>
                  </li>
                  <li>
                    <Link to="">{t('Sports')}</Link>
                  </li>
                </ul>
              </div>
              <div className="footer_cols">
                <h6>{t('Info')}</h6>
                <ul>
                  <li>
                    <Link to="about-us" onClick={scrollToTop}>{t('About Us')}</Link>
                  </li>
                  <li>
                    <Link to="terms-condition" onClick={scrollToTop}>{t('Terms & Conditions')}</Link>
                  </li>
                  <li>
                    <Link to="responsible-games" onClick={scrollToTop}>{t('Responsible Gaming')}</Link>
                  </li>
                  <li>
                    <Link to="privecy-policy" onClick={scrollToTop}>{t('Privacy Policy')}</Link>
                  </li>
                </ul>
              </div>
              <div className="footer_cols">
                <h6>{t('Payment Methods')}</h6>
                <div className="coinbox">
                  <img src="/images/home/coins/currency_bnb.png" alt="coin" />
                  <img src="/images/home/coins/currency_bch.png" alt="coin" />
                  <img src="/images/home/coins/currency_btc.png" alt="coin" />
                  <img src="/images/home/coins/currency_doge.png" alt="coin" />
                  <img src="/images/home/coins/currency_eth.png" alt="coin" />
                  <img src="/images/home/coins/currency_ltc.png" alt="coin" />
                  <img src="/images/home/coins/currency_trx.png" alt="coin" />
                  <img src="/images/home/coins/currency_usdt.png" alt="coin" />
                  <img src="/images/home/coins/currency_xrp.png" alt="coin" />
                </div>
                <div className="paybox">
                  <img src="/images/footer/pay1.png" alt="coin" />
                  <img src="/images/footer/pay2.png" alt="coin" />
                  <img src="/images/footer/pay3.png" alt="coin" />
                </div>
              </div>
            </div>
            <div className="footer_copyright">
              <div className="social_block">
                <p>{t('Follow us')}</p>
                <div className="footer_social">
                  <Link to="">
                    <div className="footer_social_icon">
                      <img
                        src="/images/footer/facebook.png"
                        alt="footer_social"
                      />
                    </div>
                  </Link>
                  <Link to="">
                    <div className="footer_social_icon">
                      <img src="/images/footer/insta.png" alt="footer_social" />
                    </div>
                  </Link>
                  <Link to="">
                    <div className="footer_social_icon">
                      <img src="/images/footer/twitter.png" alt="footer_social" />
                    </div>
                  </Link>
                  {/* <Link to="">
                    <div className="footer_social_icon">
                      <img
                        src="/images/footer/telegram.png"
                        alt="footer_social"
                      />
                    </div>
                  </Link> */}
                </div>
              </div>
              <div className="messagebox">
                <p>{t('All Rights Reserved')} 2023 Paradise7</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
