import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userId: sessionStorage.getItem('userId') || null,
    username: sessionStorage.getItem('username') || null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userId = action.payload.userId;
      state.username = action.payload.username;
    },
    clearUserData: (state) => {
      state.userId = null;
      state.username = null;
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;

export const userInfoData = (state) => state.userInfo;

export default userSlice.reducer;
