import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import './custom_button.css';

const CustomButton = (props) => {
  return (
    <Button
      variant={props.variant ?? 'primary'}
      className={props.class}
      id="custom-button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.loading ? (
        <span
          className="spinner-border spinner-border-sm me-2"
          aria-hidden="true"
        ></span>
      ) : null}
      {props.text}
    </Button>
  );
};

CustomButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  class: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default CustomButton;
