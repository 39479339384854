import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './livecasino_card.css';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Login from '../../../pages/auth/login/Login';
import ShowGames from '../../../pages/casino/ShowGames';
import GameModal from '../modal/GameModal';
import PlaceholderImage from './loading.png';

import 'react-lazy-load-image-component/src/effects/blur.css';

function LiveCasinoCard(props) {
  const auth = useAuthUser();
  const authData = auth();
  const isAuthenticatedState = useIsAuthenticated();
  const isAuthenticated = isAuthenticatedState();
  const navigate = useNavigate();

  const { gameType, data } = props;

  console.log(gameType);
  console.log(data);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#2e6cf9' : '#2e6cf8',
    },
  }));

  const [visibleItems, setVisibleItems] = useState(32);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showGame, setShowGame] = useState(false);

  function onItemClick(casino) {
    try {
      if (!authData) {
        setShowLoginModal(true);
        return;
      }
      navigate(`/play/${gameType}/${casino.gameCode}`, {
        state: { location_path: 'slot-casino' },
      });
    } catch (error) {
      toast.error('Game load failed!');
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      setShowLoginModal(false);
    }
  }, [isAuthenticated]);

  const onCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const onLoadMore = () => {
    setVisibleItems((prev) => prev + 24);
  };

  const onLoadAll = () => {
    setVisibleItems(data.length);
  };

  return (
    <div className="live_casino_card_wrapper mb-5">
      <div className="live-box">
        <div className="live_casino_card 12 mt-4">
          {data?.slice(0, visibleItems).map((casino) => {
            if (casino.status !== 0) {
              return (
                <button
                  key={casino?.gameCode}
                  className="item"
                  onClick={() => onItemClick(casino)}
                >
                  <div
                    className="image-container"
                    style={{
                      backgroundColor: '#000',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <LazyLoadImage
                      src={`/liveCasino/${casino.gameTypeName}.png`}
                      alt={casino?.gameTypeName}
                      effect="blur"
                      width={120}
                      height={170}
                      placeholderSrc={PlaceholderImage}
                    />
                  </div>
                </button>
              );
            }
            return null;
          })}
        </div>

        {visibleItems < data?.length && (
          <div className="loadmore_module">
            <div className="">
              <div className="progress_bar">
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={(visibleItems / data?.length) * 100}
                />
              </div>
              <p>
                {t('Displaying')} {visibleItems} {t('of')} {data?.length}{' '}
                {t('Games')}
              </p>
            </div>
            <div className="w-100 dflex mt-3">
              <Button onClick={onLoadMore}> {t('Load more')}</Button>
              <Button onClick={onLoadAll}> {t('Load All')}</Button>
            </div>
          </div>
        )}
      </div>

      {showLoginModal && (
        <GameModal show={showLoginModal} onHide={onCloseLoginModal}>
          <Login />
        </GameModal>
      )}

      {showGame && <ShowGames />}
    </div>
  );
}

export default LiveCasinoCard;

LiveCasinoCard.propTypes = {
  data: PropTypes.array,
  gameType: PropTypes.string,
};
