import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthUser } from 'react-auth-kit';

import BettingHistoryTable from '../../../components/tables/BettingHistoryTable';
import { defaultConfig } from '../../../configs';
import { ApiService } from '../../../services/api.service';

function MyBetTable({ gameType, gameCode }) {
  // console.log(gameType)
  // console.log(gameCode)
  const auth = useAuthUser();
  const authUserData = auth();
  const userId = authUserData.id;

  const [betData, setBetData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function GetBetData() {
      try {
        const response = await ApiService({
          url: `${defaultConfig.BASE_API_URL}/user/bet/getBetList?gameType=${gameType}&gameCode=${gameCode}&date=2023-11-13&nCurPage=1&rows=10&userId=${userId}`,
          method: 'GET',
        });

        console.log(response)

        setBetData(response.data.betResult);
      } catch (error) {
        console.error('Error fetching bet data: ', error);
      } finally {
        setLoading(false);
      }
    }

    GetBetData();
  }, []);

  return <BettingHistoryTable data={betData} loading={loading} />;
}

MyBetTable.propTypes = {
  gameType: PropTypes.string,
  gameCode: PropTypes.string,
};

export default MyBetTable;
