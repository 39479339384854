// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/other/about_banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/images/other/privacy_banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../public/images/other/term_condition.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../public/images/other/responsive_game.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bigbanner_section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 330px;
    background-repeat: no-repeat;
    background-size: cover;
}
.about_page_banner{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.policy_page_banner{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.condition_page_banner{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.responsible_gaming_banner{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.bigbanner_section h1{
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}


@media(max-width:767px){
    .about_page_banner{
        background-position: 50%;
    }
    .bigbanner_section h1{
        font-size: 40px;
    }    
}
@media(max-width:575px){
    .bigbanner_section h1 {
        font-size: 30px;
    }
    .bigbanner_section {
        height: 260px;
    }
}

@media(max-width:450px){
    .bigbanner_section h1 {
        font-size: 26px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/bigbanner/banner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,yDAAuE;AAC3E;AACA;IACI,yDAAyE;AAC7E;AACA;IACI,yDAAyE;AAC7E;AACA;IACI,yDAA0E;AAC9E;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;AAC9B;;;AAGA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".bigbanner_section{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 330px;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.about_page_banner{\n    background-image: url(../../../../public/images/other/about_banner.png);\n}\n.policy_page_banner{\n    background-image: url(../../../../public/images/other/privacy_banner.png);\n}\n.condition_page_banner{\n    background-image: url(../../../../public/images/other/term_condition.png);\n}\n.responsible_gaming_banner{\n    background-image: url(../../../../public/images/other/responsive_game.png);\n}\n.bigbanner_section h1{\n    text-align: center;\n    font-size: 44px;\n    font-style: normal;\n    font-weight: 700;\n    text-transform: capitalize;\n}\n\n\n@media(max-width:767px){\n    .about_page_banner{\n        background-position: 50%;\n    }\n    .bigbanner_section h1{\n        font-size: 40px;\n    }    \n}\n@media(max-width:575px){\n    .bigbanner_section h1 {\n        font-size: 30px;\n    }\n    .bigbanner_section {\n        height: 260px;\n    }\n}\n\n@media(max-width:450px){\n    .bigbanner_section h1 {\n        font-size: 26px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
