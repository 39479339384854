import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function BettingHistoryTable({ data, loading }) {
  const { t } = useTranslation();
  const thead = [
    {
      id: 1,
      name: t('Number'),
    },
    {
      id: 2,
      name: t('Name'),
    },
    {
      id: 3,
      name: t('Game'),
    },
    {
      id: 4,
      name: t('Situation'),
    },
    {
      id: 5,
      name: t('BettingMoney'),
    },
    {
      id: 6,
      name: t('Winings'),
    },
    { 
      id: 7,
      name: t('BettingDate&Time'),
    },
  ];

  const renderTableBody = () => {

    if (loading) {
      return (
        <tr>
          <td colSpan="10" className="empty_tr">
            <Spinner animation="border" />
          </td>
        </tr>
      );
    }

    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan="10" className="empty_tr">
            {t('NoDataAvailable')}
          </td>
        </tr>
      );
    }

    return data.map((item, index) => (
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>{item.userName}</td>
        <td>{item.gameDataTitle}</td>
        <td>
          {item.betStatus === 0 && (
            <p className="bet-status-betting">{t('Betting')}</p>
          )}
          {item.betStatus === 1 && <p className="bet-status-loss">{t('Loss')}</p>}
          {item.betStatus === 2 && <p className="bet-status-win">{t('Win')}</p>}
          {item.betStatus === 4 && <p className="bet-status-cancel">{t('Cancel')}</p>}
          {item.betStatus !== 0 &&
            item.betStatus !== 1 &&
            item.betStatus !== 2 &&
            item.betStatus !== 4 && (
              <p className="bet-status-unknown">{t('Unknown')}</p>
            )}
        </td>
        <td>{item.betMoney}</td>
        <td>{item.winMoney}</td>
        <td>{item.transactionTimestamp}</td>
      </tr>
    ));
  };

  return (
    <div className="table-responsive scrollY scrollX">
      <table className="commonTable">
        <thead>
          <tr>
            {thead.map((th) => (
              <th key={th.id}>{th.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
    </div>
  );
}

BettingHistoryTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      gameDataTitle: PropTypes.string.isRequired,
      betStatus: PropTypes.number.isRequired,
      betMoney: PropTypes.number.isRequired,
      winMoney: PropTypes.number.isRequired,
      transactionTimestamp: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BettingHistoryTable;
