import i18n from 'i18next';
import cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: cookies.get('lang') || 'en',
  fallbackLng: 'ko',
  supportedLngs: ['en', 'ko'],
  debug: false,
  resources: {
    en: {
      translation: require('./locales/en/translation.json'),
      
    },
    ko: {
      translation: require('./locales/ko/translation.json'),
    },
  },
});

export default i18n;
