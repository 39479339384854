import React from 'react';

import './loader.css';

function Loader() {
  return (
    <div className="loader_wrapper">
      <div className="LoaderBalls">
        <div className="LoaderBalls__item"></div>
        <div className="LoaderBalls__item"></div>
        <div className="LoaderBalls__item"></div>
      </div>
    </div>
  );
}

export default Loader;
