import { configureStore } from '@reduxjs/toolkit';

import mainReducer from './reducers/mainSlice';
import userReducer from './reducers/userSlice';

export const store = configureStore({
  reducer: {
    maindata: mainReducer,
    userInfo: userReducer,
  },
});
