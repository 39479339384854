import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Navigation from '../components/mobile_navigation/Navigation';
import Sidebar from '../components/sidebar/Sidebar';
import Loader from '../components/ui/loader/Loader';

function Index() {
  function menuToggler() {
    document.body.classList.remove('toggle_menu');
  }

  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <Sidebar />
      <div className="main_content">
        <div className="bg" onClick={menuToggler}></div>
        <div className="main_inner">
          <Outlet />
          <Footer />
        </div>
        <Navigation />
      </div>
    </Suspense>
  );
}

export default Index;
