import Icons from '../components/icons';

export const sidebarConfig = {
  routesNoAuth: [
    {
      id: 1,
      url: '/live-casino',
      hasInnerMenu: false,
      page_name: 'Live Casino',
      hover_msg: 'Live Casino',
      icon: <Icons.LiveCasino />,
      disabled: false,
    },
    {
      id: 2,
      url: '/slots',
      hasInnerMenu: false,
      page_name: 'Slots',
      hover_msg: 'Slots',
      icon: <Icons.Slots />,
      disabled: false,
    },
    {
      id: 3,
      url: '/live-dealer',
      hasInnerMenu: false,
      page_name: 'Live Dealer',
      hover_msg: 'coming_soon',
      icon: <Icons.LiveDealer />,
      disabled: true,
    },
    {
      id: 4,
      url: '/crash-games',
      hasInnerMenu: false,
      page_name: 'Crash games',
      hover_msg: 'coming_soon',
      icon: <Icons.CrashGames />,
      disabled: true,
    },
    {
      id: 5,
      url: '/roulette',
      hasInnerMenu: false,
      page_name: 'Roulette',
      hover_msg: 'coming_soon',
      icon: <Icons.Roulette />,
      disabled: true,
    },
    {
      id: 6,
      url: '/slot-casino',
      hasInnerMenu: false,
      page_name: 'SlotCasino',
      hover_msg: 'Slot Casino',
      icon: <Icons.SlotCasino />,
      disabled: false,
    },
    {
      id: 7,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Sport',
      hover_msg: 'coming_soon',
      icon: <Icons.Sport />,
      disabled: true,
    },
    {
      id: 8,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Blackjack',
      hover_msg: 'coming_soon',
      icon: <Icons.Blackjack />,
      disabled: true,
    },
    {
      id: 9,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Jackpot Games',
      hover_msg: 'coming_soon',
      icon: <Icons.JackpotGames />,
      disabled: true,
    },
    {
      id: 10,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Sports',
      hover_msg: 'coming_soon',
      icon: <Icons.Sports />,
      disabled: true,
    },
    {
      id: 11,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Promotions',
      hover_msg: 'coming_soon',
      icon: <Icons.Promotions />,
      disabled: true,
    },
    {
      id: 12,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Loyality',
      hover_msg: 'coming_soon',
      icon: <Icons.Loyality />,
      disabled: true,
    },
    {
      id: 13,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Buy Crypto',
      hover_msg: 'coming_soon',
      icon: <Icons.BuyCrypto />,
      disabled: true,
    },
    {
      id: 14,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Help',
      hover_msg: 'coming_soon',
      icon: <Icons.Help />,
      disabled: true,
    },
  ],
  routesAuth: [
    // {
    //   id: 1,
    //   url: '/slot-game',
    //   hasInnerMenu: false,
    //   page_name: 'SlotGame',
    //   hover_msg: 'Slot Game',
    //   icon: <Icons.SlotGame />,
    //   disabled: false,
    // },
    {
      id: 1,
      url: '/live-casino',
      hasInnerMenu: false,
      page_name: 'Live Casino',
      hover_msg: 'Live Casino',
      icon: <Icons.LiveCasino />,
      disabled: false,
    },
    {
      id: 2,
      url: '/slots',
      hasInnerMenu: false,
      page_name: 'Slots',
      hover_msg: 'Slots',
      icon: <Icons.Slots />,
      disabled: false,
    },
    {
      id: 3,
      url: '/live-dealer',
      hasInnerMenu: false,
      page_name: 'Live Dealer',
      hover_msg: 'coming_soon',
      icon: <Icons.LiveDealer />,
      disabled: true,
    },
    {
      id: 4,
      url: '/crash-games',
      hasInnerMenu: false,
      page_name: 'Crash games',
      hover_msg: 'coming_soon',
      icon: <Icons.CrashGames />,
      disabled: true,
    },
    {
      id: 5,
      url: '/roulette',
      hasInnerMenu: false,
      page_name: 'Roulette',
      hover_msg: 'coming_soon',
      icon: <Icons.Roulette />,
      disabled: true,
    },
    {
      id: 6,
      url: '/slot-casino',
      hasInnerMenu: false,
      page_name: 'SlotCasino',
      hover_msg: 'SlotCasino',
      icon: <Icons.SlotCasino />,
      disabled: false,
    },
    {
      id: 7,
      url: '/',
      hasInnerMenu: false,
      page_name: 'Sports',
      hover_msg: 'coming_soon',
      icon: <Icons.Sport />,
      disabled: true,
    },
    {
      id: 8,
      url: 'casino/bet-history',
      page_name: 'BetHistory',
      hover_msg: 'BetHistory',
      hasInnerMenu: false,
      icon: <Icons.BetHistory />,
      disabled: false,
    },
    {
      id: 9,
      url: 'casino/deposit-history',
      page_name: 'deposit_history',
      hover_msg: 'deposit_history',
      hasInnerMenu: false,
      icon: <Icons.DepositHistory />,
      disabled: false,
    },
    {
      id: 10,
      url: 'casino/withdrawal-history',
      page_name: 'WithdrawalHistory',
      hover_msg: 'WithdrawalHistory',
      hasInnerMenu: false,
      icon: <Icons.WithdrawalHistory />,
      disabled: false,
    },
    {
      id: 11,
      url: '/events',
      hasInnerMenu: false,
      page_name: 'Events',
      hover_msg: 'Events',
      icon: <Icons.Events />,
      disabled: false,
    },
  ],
};
