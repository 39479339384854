import React from 'react'
import './banner.css'

const BigBanner = (props) => {
  return (
    <div className={`bigbanner_section ${props.image_class}`}>
        <h1>{props.banner_title}</h1>
    </div>
  )
}

export default BigBanner