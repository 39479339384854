import React from 'react'
import { useTranslation } from 'react-i18next';
import BigBanner from '../../../components/ui/bigbanner/bigbanner'

const ResponsibleGames = () => {
    const { t } = useTranslation();
    return (
        <div className='responsible_game_page'>
            <BigBanner banner_title={t('Responsible Gaming')} image_class="responsible_gaming_banner" />
            <div className="common_pages_section">
                <div className="container">
                    <div className="common_info_section">
                        <h4>{t('Promoting Responsible Gaming')}</h4>
                        <p>{t('Gaming_1')}</p>
                        <p>{t('Gaming_2')}</p>
                        <div className="accordian_list new">
                            <ol>
                                <li>
                                    <p>{t('Gaming_3')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_4')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_5')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_6')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_7')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_8')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_9')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_10')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_11')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_12')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_13')}</p>
                                </li>
                                <li>
                                    <p>{t('Gaming_14')}</p>
                                </li>
                            </ol>
                            <p>{t('Gaming_15')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResponsibleGames