import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import Icons from '../icons';

function LanguageChanger() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(Cookies.get('lang'));

  function changeLanguage(lang) {
    if (value === lang) return;
    Cookies.set('lang', lang);
    setValue(lang);
    i18n.changeLanguage(lang);
  }
  const spanStyle = {
    marginLeft: '8px',
  };

  return (
    <RadioGroup
      className="menu_language_block"
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="English"
      name="radio-buttons-group"
      value={value}
    >
      <FormControlLabel
        labelPlacement="start"
        value="en"
        control={<Radio />}
        label={
          <>
            <Icons.English />
            <span className='langName' style={spanStyle}>{t('English')}</span>
          </>
        }
        onClick={() => changeLanguage('en')}
      />
      <FormControlLabel
        labelPlacement="start"
        value="ko"
        control={<Radio />}
        label={
          <>
            <Icons.Korean />
            <span className='langName' style={spanStyle}>{t('Korean')}</span>
          </>
        }
        onClick={() => changeLanguage('ko')}
      />
    </RadioGroup>
  );
}

export default LanguageChanger;
