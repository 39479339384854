import io from 'socket.io-client';

const socket_clientdata = io(
  `${process.env.REACT_APP_WEBSOCKET_URL}/clientdata`,
  {
    transports: ['websocket', 'polling'],
  }
);

export const connectSocket = () => {
  socket_clientdata.on('connect', () => {
    console.log('Socket Connected');
  });
};

export const disconnectSocket = () => {
  socket_clientdata.disconnect();
};

connectSocket();

export { socket_clientdata };
