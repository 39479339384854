import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './game_tab.css';
import '../casino_home.css';
import '../../../components/ui/searchBar/search_bar.css';

import { Autocomplete, TextField } from '@mui/material';
import { useAuthUser } from 'react-auth-kit';
import { useIsAuthenticated } from 'react-auth-kit/dist';
import { Button } from 'react-bootstrap';

import Icons from '../../../components/icons';
// import LiveCasinoCard from '../../../components/ui/slotCasinoCard/LiveCasinoCard';
import LiveCasinoCard1 from '../../../components/ui/liveCasinoCard1/LiveCasinoCard';

import Loader from '../../../components/ui/loader/Loader';
import { defaultConfig } from '../../../configs';
import { ApiService } from '../../../services/api.service';
import SlotsListCard from '../../../components/ui/liveCasinoCard1/SlotsListCard';

function SlotsgameList(gameType, gameCode) {
  const params = useParams();
  const { t } = useTranslation();
  const auth = useAuthUser();
  const authUserData = auth();
  const userId = authUserData.id;
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { id } = params;

  const [searchResult, setSearchResult] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        const response = await ApiService({
          url: `${defaultConfig.BASE_API_URL}/user/getSlotgames/${id}`,
          method: 'GET',
        });

        console.log(response.data.slotGamesList);
        setData(response.data.slotGamesList);
      } catch (error) {
        console.error('Error fetching bet data: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  console.log(data);

  const handleSearchresult = () => {
    setSearchResult(!searchResult);
  };

  return (
    <div className="casino_home_page">
      <div className="container">
        <div className="casino_home_inner">
          <div className="casino_banner">
            <div className="casino_title">
              <h3>{t('Experience the Next Level of Entertainment')}</h3>
              <p>{t('Elevate')}</p>
              {!isAuthenticated() ? <Button>{t('signin')}</Button> : null}
            </div>
          </div>
          <div className="heading">
            <h5>{t('Slots')}</h5>
          </div>
          {searchResult ? (
            <div className="bg_blur" onClick={handleSearchresult}></div>
          ) : (
            ''
          )}
          <div className="searchbox_wrapper">
            <div className="searchbox">
              <span>
                <Icons.Search />
              </span>
              <input
                type="text"
                placeholder={t('Search')}
                value={searchQuery}
                onClick={handleSearchresult}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {searchResult ? (
              <div className="results_wrapper scrollY scrollX">
                {data !== null ? (
                  <SlotsListCard
                    gameType="slot-casino"
                    data={
                      searchQuery
                        ? data.filter((game) =>
                            game.gameName
                              ?.toLowerCase()
                              ?.includes(searchQuery.toLowerCase())
                          )
                        : data
                    }
                  />
                ) : (
                  <p className="noresult">
                    Search requires at least 3 characters.
                  </p>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="slots">
            {data !== null ? (
              <SlotsListCard
                gameType="slot"
                data={
                  searchQuery
                    ? data.filter((game) =>
                        game.gameTypeName
                          ?.toLowerCase()
                          ?.includes(searchQuery.toLowerCase())
                      )
                    : data
                }
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlotsgameList;
