// Login.js

import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useSignIn } from 'react-auth-kit';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoginSocialFacebook } from 'reactjs-social-login';

import Icons from '../../../components/icons';
import CustomButton from '../../../components/ui/button/CustomButton';
import Loader from '../../../components/ui/loader/Loader';
import { defaultConfig } from '../../../configs';
import { setPageInfo } from '../../../redux/reducers/mainSlice';
import Policy from '../../other/policy_page/policy';
import TermCondition from '../../other/term_condition/term_condition';
import Signup from '../register/Signup';
import { isNotEmpty } from '../validation/Validation';

function Login() {
  const { t } = useTranslation();
  const signIn = useSignIn();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function validateInput(inputId, inputValue) {
    if (!isNotEmpty(inputValue)) {
      document.getElementById(inputId).classList.add('input-error');
      return true;
    }
    return false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      validateInput('username', formData.username) ||
      validateInput('password', formData.password)
    ) {
      toast.error('Invalid Credentials!');
      return;
    }

    setIsLoading(true);

    await axios
      .post(`${defaultConfig.BASE_API_URL}/login/onLogin`, {
        userID: formData.username,
        userPW: formData.password,
      })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          if (
            signIn({
              token: res.data.data.token,
              expiresIn: res.data.data.expiresIn ?? 60,
              tokenType: 'Bearer',
              authState: res.data.data.userInfo,
            })
          ) {
            toast.success(t('login_success')); // 성공적 로그인

            // set user data
            const userData = res.data.data.userInfo;
            dispatch(
              setPageInfo({
                name: userData.name,
                money: userData.money,
              })
            );

            setIsLoading(false);
            if (location.pathname === '/') {
              navigate('/casino/home');
            }
          } else {
            toast.error('Invalid Credentials!');
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong!');
        setIsLoading(false);
      });
  };

  const handlePrivacyPolicyClick = () => {
    setShowPrivacyPolicy(true);
    setShowTerms(false);
    setShowRegister(false); // Hide the registration page
  };

  const handleTermsClick = () => {
    setShowTerms(true);
    setShowPrivacyPolicy(false);
    setShowRegister(false); // Hide the registration page
  };

  const handleComponent = () => {
    setShowRegister(true);
    setShowPrivacyPolicy(false); // Hide the Privacy Policy page
    setShowTerms(false); // Hide the Terms & Conditions page
  };

  // Google login
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await axios
        .post(`${defaultConfig.BASE_API_URL}/login/onOAuthLogin`, {
          token: tokenResponse.access_token,
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            if (
              signIn({
                token: res.data.data.token,
                expiresIn: res.data.data.expiresIn ?? 60,
                tokenType: 'Bearer',
                authState: res.data.data.userInfo,
              })
            ) {
              toast.success(t('login_success')); // 성공적 로그인
              setIsLoading(false);
              if (location.pathname === '/') {
                navigate('/casino/home');
              }
            } else {
              toast.error('Google authentication failed!');
              setIsLoading(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Something went wrong!');
          setIsLoading(false);
        });
    },
  });

  const handleFacebookLogin = async (response) => {
    console.log('response:', response);

    if (response.status === 'connected') {
      console.log('User is already logged in');

      // Access the user's access token
      const accessToken = response.authResponse.accessToken;
      console.log('Access token:', accessToken);
    } else {
      console.log('User is not logged in');

      if (!window.FB) {
        // Load the Facebook SDK dynamically
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      }

      // Wait for the SDK to load before initializing
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0',
        });

        // Now that the SDK is initialized, you can use FB.getLoginStatus or other FB functions
        window.FB.getLoginStatus(function (loginResponse) {
          // Handle login status
          console.log('Login status:', loginResponse);
        });
      };
    }
  };

  const handlePopupSubmit = async (username, password) => {
    setIsPopupOpen(false);
  };

  // const PopupComponent = ({ onSubmit, onClose }) => {
  //   const [username, setUsername] = useState('');
  //   const [password, setPassword] = useState('');

  //   const handleSubmit = () => {
  //     onSubmit(username, password);
  //   };

  //   return (
  //     <Modal show={true} onHide={onClose}>
  //       <Modal.Header closeButton>
  //         <Modal.Title>Enter Username and Password</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <Form>
  //           <Form.Group controlId="formUsername">
  //             <Form.Label>Username</Form.Label>
  //             <Form.Control
  //               type="text"
  //               value={username}
  //               onChange={(e) => setUsername(e.target.value)}
  //             />
  //           </Form.Group>
  //           <Form.Group controlId="formPassword">
  //             <Form.Label>Password</Form.Label>
  //             <Form.Control
  //               type="password"
  //               value={password}
  //               onChange={(e) => setPassword(e.target.value)}
  //             />
  //           </Form.Group>
  //         </Form>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="primary" onClick={handleSubmit}>
  //           Submit
  //         </Button>
  //         <Button variant="secondary" onClick={onClose}>
  //           Close
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  // useEffect(() => {
  //   window.fbAsyncInit = function() {
  //     window.FB.init({
  //       appId: process.env.REACT_APP_FACEBOOK_APP_ID,
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: 'v12.0',
  //     });
  //   };

  //   (function(d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));

  // }, []);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!showRegister && !showPrivacyPolicy && !showTerms ? (
            <Container className="">
              <Row>
                <Col md={12} lg={6} xl={5} className="mx-auto login-box">
                  <h2>{t('signin')}</h2>
                  <Form>
                    <Form.Group controlId="username" className={`mt-4`}>
                      <Form.Label>
                        {t('UserName')} <b className="required">*</b>
                      </Form.Label>
                      <Form.Control
                        type="username"
                        placeholder={t('EnterYourUserName')}
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className={`input-style`}
                      />
                    </Form.Group>

                    <Form.Group controlId="password" className={`mt-4`}>
                      <Form.Label>
                        {t('Password')} <b className="required">*</b>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t('EnterYourPassword')}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className={`input-style`}
                      />
                    </Form.Group>
                  </Form>
                  <Row>
                    <Col md={6} className="text-center mt-4">
                      <CustomButton text={t('signin')} onClick={handleSubmit} />
                    </Col>
                    <div className="or_block">
                      <span>OR</span>
                      <hr />
                    </div>
                    <div className="social_blog">
                      <Button onClick={() => googleLogin()}>
                        <Icons.Google width="25" height="25" />
                      </Button>
                      <Button>
                        <LoginSocialFacebook
                          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                          onResolve={handleFacebookLogin}
                          onReject={(error) => {
                            console.log('Error: ', error);
                          }}
                        >
                          <Icons.Facebook width="25" height="25" />
                        </LoginSocialFacebook>
                      </Button>
                      <Button disabled>
                        <Icons.Line2 width="25" height="25" />
                      </Button>
                      <Button disabled>
                        <Icons.TikTok2 width="25" height="25" />
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    {/* {isPopupOpen && (
                      <PopupComponent
                        onSubmit={handlePopupSubmit}
                        onClose={() => setIsPopupOpen(false)}
                      />
                    )} */}
                    <Col md={6} className="others mt-2">
                      <p className="py-2">
                        {t("Don't Have An Account?")}
                        <Link to="" onClick={handleComponent}>
                          {' '}
                          {t('Register an Account')}{' '}
                        </Link>{' '}
                      </p>
                      <p className="py-2">
                        {t('hCaptcha')}{' '}
                        <span>
                          {' '}
                          <Link
                            className="privacy"
                            to="#"
                            onClick={handlePrivacyPolicyClick}
                          >
                            {t('PrivacyPolicy')}
                          </Link>
                        </span>{' '}
                        {t('And')}{' '}
                        <span>
                          <Link
                            className="privacy"
                            to="#"
                            onClick={handleTermsClick}
                          >
                            {t('TermsofService')}
                          </Link>
                        </span>{' '}
                        {t('Apply')}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          ) : null}
          {showRegister && <Signup />}
          {showPrivacyPolicy && <Policy />}
          {showTerms && <TermCondition />}
        </>
      )}
    </div>
  );
}

export default Login;
