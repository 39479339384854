// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgb(15 27 34);
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.LoaderBalls {
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LoaderBalls__item {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}

.LoaderBalls__item:nth-child(1) {
  animation: bouncing 0.4s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95);
}

.LoaderBalls__item:nth-child(2) {
  animation: bouncing 0.4s 0.1s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
}

.LoaderBalls__item:nth-child(3) {
  animation: bouncing 0.4s 0.2s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }
  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}

.pageLoader {
  background-color: #233742c2;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 600px;
  min-height: 585px;
  z-index: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/loader/loader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE;uCACqC;AACvC;;AAEA;EACE;iDAC+C;AACjD;;AAEA;EACE;iDAC+C;AACjD;;AAEA;EACE;IACE,mDAAmD;EACrD;EACA;IACE,mDAAmD;EACrD;AACF;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".loader_wrapper {\n  width: 100%;\n  height: 100vh;\n  background-color: rgb(15 27 34);\n  position: fixed;\n  z-index: 100;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  left: 0;\n}\n\n.LoaderBalls {\n  width: 40px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.LoaderBalls__item {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background: #fff;\n}\n\n.LoaderBalls__item:nth-child(1) {\n  animation: bouncing 0.4s alternate infinite\n    cubic-bezier(0.6, 0.05, 0.15, 0.95);\n}\n\n.LoaderBalls__item:nth-child(2) {\n  animation: bouncing 0.4s 0.1s alternate infinite\n    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;\n}\n\n.LoaderBalls__item:nth-child(3) {\n  animation: bouncing 0.4s 0.2s alternate infinite\n    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;\n}\n\n@keyframes bouncing {\n  0% {\n    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);\n  }\n  100% {\n    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);\n  }\n}\n\n.pageLoader {\n  background-color: #233742c2;\n  position: relative;\n  height: 100%;\n  width: 100%;\n  max-height: 600px;\n  min-height: 585px;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
