// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-button {
  border: 2px solid #1475e1;
  color: #fff;
  font-weight: bold !important;
  text-align: center;
  display: block;
  width: 100%;
  border-radius: 5px !important;
  padding: 10px !important;
  background-color: red;
}

#custom-button:hover {
  border: 2px solid #1475e1;
}

#custom-button.primarybtn {
  background: #2f4553;
  border-color: #2f4553;
}

#custom-button.primarybtn:hover {
  background-color: #223541;
}

@media (max-width: 1280px) {
  #custom-button {
    font-size: 14px;
    padding: 7px 10px !important;
  }
}

@media (max-width: 1199px) {
  #custom-button {
    font-size: 13px;
    padding: 4px 7px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/button/custom_button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,4BAA4B;EAC5B,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,6BAA6B;EAC7B,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,eAAe;IACf,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,eAAe;IACf,2BAA2B;EAC7B;AACF","sourcesContent":["#custom-button {\n  border: 2px solid #1475e1;\n  color: #fff;\n  font-weight: bold !important;\n  text-align: center;\n  display: block;\n  width: 100%;\n  border-radius: 5px !important;\n  padding: 10px !important;\n  background-color: red;\n}\n\n#custom-button:hover {\n  border: 2px solid #1475e1;\n}\n\n#custom-button.primarybtn {\n  background: #2f4553;\n  border-color: #2f4553;\n}\n\n#custom-button.primarybtn:hover {\n  background-color: #223541;\n}\n\n@media (max-width: 1280px) {\n  #custom-button {\n    font-size: 14px;\n    padding: 7px 10px !important;\n  }\n}\n\n@media (max-width: 1199px) {\n  #custom-button {\n    font-size: 13px;\n    padding: 4px 7px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
