import React from 'react'
import BigBanner from '../../../components/ui/bigbanner/bigbanner'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const Policy = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      title: t('Special Offers'),
      paragraph: t('Policy_3')
    },
    {
      id: 2,
      title: t('Online Surveys'),
      paragraph:t('Policy_4')
    },
    {
      id: 3,
      title: t('Cookies'),
      paragraph: t('Policy_5')
    },
    {
      id: 4,
      title: t('Disclosure of Information We Collect'),
      paragraph: t('Policy_6'),
      extra_info: [
        {
          id: 1,
          listname: t('Point_1')
        },
        {
          id: 2,
          listname: t('Point_2')
        },
        {
          id: 3,
          listname: t('Point_3')
        },
      ]
    },
    {
      id: 5,
      title: t('Our Commitment to Data Security'),
      paragraph: t('Policy_7'),
    },
    {
      id: 6,
      title: t('External Links'),
      paragraph:  t('Policy_8'),
    },
  ]
  return (
    <div className='privacy_page'>
      <BigBanner banner_title={t('Privacy Policy')} image_class="policy_page_banner" />
      <div className="common_pages_section">
        <div className="container">
          <div className="common_info_section">
            <h4>{t('Privacy Policy')}</h4>
            <p>{t('Policy_1')}</p>
          </div>
          <div className="common_info_section">
            <h4>{t('Information We Gather')}</h4>
            <p>{t('Policy_2')}</p>
          </div>
          <div className="cm_accordian_block">
            <h3>{t('Categories of Information We Collect')}</h3>
            <Accordion defaultActiveKey="1" className='cm_accordian'>
              {data.map((items) => {
                return (
                  <Accordion.Item key={items?.id} eventKey={items?.id}>
                    <Accordion.Header>{items?.title}</Accordion.Header>
                    <Accordion.Body>
                      {items?.paragraph}
                      <div className="" style={{marginTop:"5px"}}>
                        {items?.extra_info?.map((list) => {
                          return (
                            <p key={list?.id}>{list?.listname}</p>
                          )
                        })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
          <div className="common_info_section mt-5">
            <h4>{t('Policy_9')}</h4>
            <p>{t('Policy_10')}</p>
          </div>
          <div className="common_info_section">
            <h4>{t('Policy_11')}</h4>
            <p>{t('Policy_12')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policy