import { React, useState } from 'react';
import './navigation.css';
import { NavLink } from 'react-router-dom';
import Icons from '../icons';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState('false');
  const ToggleClass = () => {
    document.body.classList.toggle('toggle_menu');
    setIsActive(!isActive);
  };

  const menuTogglerRemove = () => {
    document.body.classList.remove('toggle_menu');
  };

  return (
    <div className="navigation_wrapper">
      <button
        className={isActive ? 'navbutton' : 'navbutton on'}
        onClick={ToggleClass}
      >
        <span>
          <Icons.MobiNavBrowse />
        </span>
        <span>{t('Browse')}</span>
      </button>
      <NavLink
        to="/casino/home"
        className="navbutton"
        onClick={menuTogglerRemove}
      >
        <span>
          <Icons.MobiNavCasino />
        </span>
        <span>{t('Casino')}</span>
      </NavLink>
      <NavLink to="/casino/bet-history" className="navbutton" onClick={menuTogglerRemove}>
        <span>
          <Icons.MobiNavBets />
        </span>
        <span>{t('Bets')}</span>
      </NavLink>
      <NavLink
        // to="/sports/home"
        className="navbutton"
        onClick={menuTogglerRemove}
      >
        <span>
          <Icons.MobiNavSports />
        </span>
        <span>{t('Sports')}</span>
      </NavLink>

      {/* <NavLink to="/chat" className="navbutton" onClick={menuTogglerRemove}>
        <span>
          <Icons.MobiNavChat />
        </span>
        <span>Chat</span>
      </NavLink> */}
    </div>
  );
};

export default Navigation;
