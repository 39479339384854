// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  border-radius: 11px !important;
}

.custom-modal .modal-header {
  background-color: #182c38 !important;
  border: none !important;
  border-bottom: none !important;
  margin: 0 !important;
  /* padding: 0 !important; */
}

.custom-modal .modal-header h5 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-modal .modal-header .close {
  background-color: transparent;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
}

.custom-modal .modal-header .close span {
  color: #aab3cb;
  font-size: 40px;
  text-align: right;
  margin-right: 1%;
}

.modal-body {
  background-color: #182c38;
  padding-top: 0px !important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.authModal .modal-header {
  padding: 15px;
  padding-bottom: 0;
}

/* Game Modal  */

.custom-modal .modal-header1 {
  background-color: #182c38 !important;
  border: none !important;
  border-bottom: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/modal/common_modal.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,8BAA8B;EAC9B,oBAAoB;EACpB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA,gBAAgB;;AAEhB;EACE,oCAAoC;EACpC,uBAAuB;EACvB,8BAA8B;EAC9B,oBAAoB;EACpB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".modal-content {\n  border-radius: 11px !important;\n}\n\n.custom-modal .modal-header {\n  background-color: #182c38 !important;\n  border: none !important;\n  border-bottom: none !important;\n  margin: 0 !important;\n  /* padding: 0 !important; */\n}\n\n.custom-modal .modal-header h5 {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.custom-modal .modal-header .close {\n  background-color: transparent;\n  border: none;\n  width: -moz-fit-content;\n  width: fit-content;\n  height: 46px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 2%;\n}\n\n.custom-modal .modal-header .close span {\n  color: #aab3cb;\n  font-size: 40px;\n  text-align: right;\n  margin-right: 1%;\n}\n\n.modal-body {\n  background-color: #182c38;\n  padding-top: 0px !important;\n  border-bottom-left-radius: 7px;\n  border-bottom-right-radius: 7px;\n}\n\n.authModal .modal-header {\n  padding: 15px;\n  padding-bottom: 0;\n}\n\n/* Game Modal  */\n\n.custom-modal .modal-header1 {\n  background-color: #182c38 !important;\n  border: none !important;\n  border-bottom: none !important;\n  margin: 0 !important;\n  padding: 0 !important;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
