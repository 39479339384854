import React, { useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Icons from '../../components/icons';
import { defaultConfig } from '../../configs';
import { ApiService } from '../../services/api.service';
import MyBetTable from './casinoTables/MyBetTable';

import './casino_home.css';

function ShowGames() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const auth = useAuthUser();
  const userAuthData = auth();

  const [data, setData] = useState(null);
  const iframeRef = useRef(null);

  // Extract gameData from params
  const { gameType, id } = params;

  const { apiResponse } = location.state || {};
  const gameLinkFromState = apiResponse?.data?.gameLink;

  const [gameLink, setGameLink] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!userAuthData || !id || !gameType) {
        return;
      }

      try {
        const response = await ApiService({
          url: `${defaultConfig.BASE_API_URL}/user/getGameLink?gameCode=${id}&gameType=${gameType}&userId=${userAuthData.name}`,
          method: 'POST',
        });
        const fetchedGameLink = response.data.gameLink;
        // console.log('fetchedGameLink' ,fetchedGameLink);
        setGameLink(fetchedGameLink);
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  function toggleFullScreen(iframe) {
    if (iframe.current) {
      if (!document.fullscreenElement) {
        iframe.current.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable full-screen mode:', err);
        });
      } else {
        document.exitFullscreen();
      }
    }
  }

  function onBackButtonClick() {
    window.history.back();
  }

  return (
    <div className="container">
      <div className="casino_home_page">
        <button
          className="btn Icon"
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            margin: '5px',
            padding: '0',
            paddingBottom: '5px',
            backgroundColor: 'transparent',
          }}
          onClick={onBackButtonClick}
        >
          <Icons.ArrowLeft />
          <p>{t('GoBack')}</p>
        </button>
        {gameLink && (
          <div className="frame_wrapper">
            <iframe
              title="Game Preview"
              src={gameLink}
              width="100%"
              height="500"
              allowFullScreen
              className="custom-iframe"
              ref={iframeRef}
            />
          </div>
        )}
        <button
          className="full-screen"
          style={{
            border: 'unset',
            backgroundColor: 'transparent',
            margin: '5px',
          }}
          onClick={() => toggleFullScreen(iframeRef)}
        >
          <Icons.FullScreen />
        </button>
        <div className="casino_home_inner mb-5">
          <div className="casino_table_tab_block">
            <MyBetTable gameType={gameType} gameCode={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowGames;
