import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiService } from '../../services/api.service';

import './common.css';

import { FormControl, MenuItem, Select } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { defaultConfig } from '../../configs';
import { pageInfoData } from '../../redux/reducers/mainSlice';
import Icons from '../icons';

function WalletModal() {
  const { t } = useTranslation();

  const pageInfo = useSelector(pageInfoData);
  const auth = useAuthUser();
  const authUserData = auth();

  const wallets = [
    {
      id: 1,
      name: 'ABM',
      address:
        authUserData.matic_address === '0'
          ? t('Address not available')
          : authUserData.matic_address,
    },
    {
      id: 2,
      name: 'USDT',
      address:
        authUserData.tron_address === '0'
          ? t('Address not available')
          : authUserData.tron_address,
    },
  ];

  const [value, setValue] = useState(0);
  const [coins, setCoins] = useState(
    pageInfo ? parseFloat(pageInfo?.money) : 0
  );
  const [coinsInput, setCoinsInput] = useState(
    pageInfo ? parseFloat(pageInfo?.money) : 0
  );
  const [sendAddress, setSendAddress] = useState('');

  const [selectedWallet, setSelectedWallet] = useState(wallets[0]);

  const handleWalletChange = (event) => {
    const selectedWalletId = parseInt(event.target.value);
    const newSelectedWallet = wallets.find(
      (wallet) => wallet.id === selectedWalletId
    );
    setSelectedWallet(newSelectedWallet);
  };
  const handleCoinSelect = (event) => {
    setCoins(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function copyClipboard() {
    const copyText = document.getElementById(
      'deposite_address_input'
    ).innerHTML;
    navigator.clipboard.writeText(copyText);
    toast.success(t('wallet_copy'));
  }

  function selectMaxCoin() {
    setCoinsInput(pageInfo ? parseFloat(pageInfo?.money) : 0);
  }

  async function withdrawalProcess() {
    if (!authUserData.id) {
      return toast.error(t('Please login first'));
    }

    // check coinsInput is a number
    if (Number.isNaN(coinsInput)) {
      return toast.error(t('Amount required number.'));
    }

    if (coinsInput <= 0) {
      return toast.error(t('Amount required more than 0.'));
    }

    // check address
    // if (!coinvalidator.validate(sendAddress, 'matic', 'prod')) {
    //   // invalid
    //   return toast.error(t('Invalid address.'));
    // } else {
    //   // valid
    //   toast.success(t('Valid address.'));
    // }

    await ApiService({
      url: `${defaultConfig.BASE_API_URL}/user/cash/onExchargeMain`,
      method: 'POST',
      data: {
        userId: authUserData.id,
        to_address: sendAddress,
        amount: Number(coinsInput),
      },
    })
      .then((response) => {
        if (response.success) {
          toast.success(`${coinsInput} Withdrawal successfully`);
        } else {
          console.error('API Error:', response);
          toast.error('Error occurred!');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        toast.error('Withdrawal failed!');
      });
  }

  return (
    <div className="vaultModal_wrapper">
      <div className="vaultModal_inner">
        <Tabs
          value={value}
          onChange={handleChange}
          className="common_tab_UI wallet_tab"
          aria-label="basic tabs example"
        >
          <Tab
            className="common_tab_btn"
            label={<span className="icontext">{t('Deposit')}</span>}
            {...a11yProps(0)}
          />
          <Tab
            className="common_tab_btn"
            label={<span className="icontext">{t('Withdraw')}</span>}
            {...a11yProps(1)}
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <div className="wallet_tab_content">
            <div className="bothselect">
              <div className="select_wrapper">
                <div className="common_selectbox">
                  <FormControl>
                    <Select
                      size="small"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      value={selectedWallet.id}
                      onChange={handleWalletChange}
                    >
                      {wallets.map((wallet) => (
                        <MenuItem key={wallet.id} value={wallet.id}>
                          {wallet.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="depositAddress">
              <label className="">
                {t('Your')} {selectedWallet.name} {t('deposit address')}
              </label>
              <div className="deposite_address">
                <p id="deposite_address_input">{selectedWallet.address}</p>
                <button className="copyicon" onClick={copyClipboard}>
                  <Icons.Clipboard />
                </button>
              </div>
            </div>
            <div className="qr_code_wrapper">
              <div className="qr_code text-center" style={{ height: '140px' }}>
                {selectedWallet.address != t('Address not available') ? (
                  <QRCodeSVG value={selectedWallet.address} />
                ) : null}
              </div>
              {/* <p>{t('ConfirmationsRequired.')}</p> */}
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="wallet_tab_content deposit_tab_content">
            <div className="coin_select">
              <div className="common_selectbox">
                <FormControl>
                  <Select
                    size="small"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value="coins"
                    onChange={handleCoinSelect}
                  >
                    <MenuItem value="coins">{coins}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="inputbox_wrapper mt-3">
              <label htmlFor="coin">
                <span>
                  <img src="/images/home/coins/currency_abm.png" alt="coin" />
                </span>{' '}
                {t('ABM Address')} <span className="text-danger">*</span>{' '}
              </label>
              <input
                type="text"
                id="coin"
                name="wallet_address"
                className="commoninput"
                onChange={(e) => setSendAddress(e.target.value)}
              />
            </div>
            <div className="inputbox_wrapper mt-3">
              <div
                className="lable_box"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <label htmlFor="coin2">
                  {t('Amount')}
                  <span className="text-danger">*</span>{' '}
                </label>
              </div>
              <div className="maxinput_wrapper">
                <div className="inner_input">
                  <input
                    type="number"
                    id="coin2"
                    placeholder="0.0000000"
                    value={coinsInput}
                    className="commoninput"
                    onChange={(e) => setCoinsInput(e.target.value)}
                  />
                </div>
                <Button className="" onClick={selectMaxCoin}>
                  {t('Max')}
                </Button>
              </div>
            </div>
            <div className="text-center mt-4">
              <Button onClick={withdrawalProcess}>{t('Withdraw')}</Button>
            </div>
          </div>
        </CustomTabPanel>
      </div>
    </div>
  );
}

const PasswordModal = ({ showModal, amount, setShowPasswordModal, userId }) => {
  const [withdrawalPassword, setWithdrawalPassword] = useState('');

  const handleModalClose = () => setShowPasswordModal(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  const handlewithdrawalPasswordInputChange = (e) => {
    setWithdrawalPassword(e.target.value);
  };

  const handleSubmitWithdraw = async () => {
    try {
      if (amount > 0) {
        const apiUrl = `${defaultConfig.BASE_API_URL}/user/cash/onExcharge?amount=${amount}&userId=${userId}&withdrawalPassword=${withdrawalPassword}`;
        const requestData = {
          userId: userId,
          withdrawalPassword: withdrawalPassword,
        };
        const response = await ApiService({
          url: apiUrl,
          data: requestData,
          method: 'POST',
          isFormdata: false,
          passToken: true,
        });

        if (response.success) {
          toast.success(`${amount} Points Withdrawal successfully`);
          setShowPasswordModal(false);
        } else {
          console.error('API Error:', response);
          toast.error('Error occurred!');
          setShowPasswordModal(false);
        }
      } else {
        toast.error('Amount Required!');
      }
    } catch (error) {
      console.error('API Error:', error);
      toast.error('The currency exchange request password is incorrect!');
    }
  };

  return (
    <Modal show={showModal} onHide={handleModalClose} className="custom-modal">
      <Modal.Header>
        <h5>{t('Enter Password')} </h5>
        <button className="close" onClick={handleModalClose}>
          <span>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>{t('Withdraw Password')}</Form.Label>
          <div className="form_col two">
            <input
              type="password"
              placeholder={t('Enter withdrawal password')}
              className="form-control"
              name="withdrawalPassword"
              value={withdrawalPassword}
              onChange={handlewithdrawalPasswordInputChange}
              ref={inputRef}
            />
          </div>
        </Form.Group>

        <div className="vault_footer">
          <div className="vault_footer_btns">
            <button
              className="common_blue_btn green"
              onClick={handleModalClose}
            >
              {' '}
              {t('Close')}
            </button>
            <button
              className="common_blue_btn gray"
              onClick={handleSubmitWithdraw}
            >
              {' '}
              {t('Submit')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="common_tab_content">{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default WalletModal;
