export const footerConfig = {
  navigations: [
    {
      id: 1,
      name: 'sports',
      subnav: [
        {
          id: 1,
          name: 'Home',
          link: '/',
        },
        {
          id: 2,
          name: 'Live',
          link: '/live',
        },
        {
          id: 3,
          name: 'Sport Rules',
          link: '/sport-rules',
        },
      ],
    },
    {
      id: 2,
      name: 'Casino',
      subnav: [
        {
          id: 1,
          name: 'Games',
          link: '/games',
        },
        {
          id: 2,
          name: 'VIP Club',
          link: '/vip-club',
        },
        {
          id: 3,
          name: 'Promotions',
          link: '/promotions',
        },
      ],
    },
    {
      id: 3,
      name: 'Support',
      subnav: [
        {
          id: 1,
          name: 'Fairness',
          link: '/fairness',
        },
        {
          id: 2,
          name: 'Affiliate',
          link: '/affiliate',
        },
        {
          id: 3,
          name: 'Responsible Gambling',
          link: '/responsible-gambling',
        },
        {
          id: 4,
          name: 'Gamble Aware',
          link: '/gamble-aware',
        },
        {
          id: 5,
          name: 'Live Support',
          link: '/live-support',
        },
        {
          id: 6,
          name: 'Help Center',
          link: '/help-center',
        },
      ],
    },
    {
      id: 4,
      name: 'Community',
      subnav: [
        {
          id: 1,
          name: 'Blog',
          link: '/blog',
          external: true,
        },
        {
          id: 2,
          name: 'Forum',
          link: '/forum',
          external: true,
        },
        {
          id: 3,
          name: 'Facebook',
          link: '/facebook',
          external: true,
        },
        {
          id: 4,
          name: 'Twitter',
          link: '/twitter',
          external: true,
        },
        {
          id: 5,
          name: 'Instagram',
          link: '/instagram',
          external: true,
        },
        {
          id: 6,
          name: 'YouTube',
          link: '/youtube',
          external: true,
        },
        {
          id: 7,
          name: 'Shop',
          link: '/shop',
          external: true,
        },
      ],
    },
    {
      id: 5,
      name: 'About Us',
      subnav: [
        {
          id: 1,
          name: 'Privacy Policy',
          link: '/privacy-policy',
        },
        {
          id: 2,
          name: 'Licenses',
          link: '/licenses',
        },
        {
          id: 3,
          name: 'AML Policy',
          link: '/aml-policy',
        },
        {
          id: 4,
          name: 'Terms of Service',
          link: '/terms-of-service',
        },
        {
          id: 5,
          name: 'Self Exclusion',
          link: '/self-exclusion',
        },
        {
          id: 6,
          name: 'Primedice',
          link: '/primedice',
        },
      ],
    },
  ],
  cards: [
    {
      id: 1,
      name: 'Litecoin',
      icon: '/images/footer/litecoin.png',
    },
    {
      id: 2,
      name: 'Bitcoin',
      icon: '/images/footer/bitcoin.png',
    },
    {
      id: 3,
      name: 'ETH',
      icon: '/images/footer/eth.png',
    },
    {
      id: 4,
      name: 'TRO',
      icon: '/images/footer/tro.png',
    },
    {
      id: 5,
      name: 'Ripple',
      icon: '/images/footer/ripple.png',
    },
    {
      id: 6,
      name: 'Dogecoin',
      icon: '/images/footer/dogecoin.png',
    },
    {
      id: 7,
      name: 'Bitcoin Cash',
      icon: '/images/footer/bitcoin-cash.png',
    },
    {
      id: 8,
      name: 'Tether',
      icon: '/images/footer/tether.png',
    },
    {
      id: 9,
      name: 'Responsible Gambling',
      icon: '/images/footer/responsible.png',
    },
    {
      id: 10,
      name: '18+',
      icon: '/images/footer/18+.png',
    },
  ],
  logo: [
    {
      id: 1,
      name: 'Crypto',
      icon: '/images/footer/crypto.png',
    },
    {
      id: 2,
      name: 'Live Admin',
      icon: '/images/footer/live-admin.png',
    },
    {
      id: 3,
      name: 'Main Partner',
      icon: '/images/footer/main-partner.png',
    },
    {
      id: 4,
      name: 'UFC',
      icon: '/images/footer/ufc.png',
    },
  ],
};
