import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './header.css';

import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSignOut } from 'react-auth-kit';
import { useIsAuthenticated } from 'react-auth-kit/dist';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { siteConfig } from '../../configs/site';
import Login from '../../pages/auth/login/Login';
import Signup from '../../pages/auth/register/Signup';
import { handleMainData, pageInfoData } from '../../redux/reducers/mainSlice';
import Icons from '../icons';
import WalletModal from '../modals/WalletModal';
import CommonModal from '../ui/modal/CommonModal';

function Header() {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const [notificationButton, setNotificationButton] = useState(null);
  const [userButton, setUserButton] = useState(null);
  const [walletButton, setWalletButton] = useState(null);

  const open = Boolean(walletButton);
  const open1 = Boolean(userButton);
  const open2 = Boolean(notificationButton);

  const wallethandleClick = (event) => {
    setWalletButton(event.currentTarget);
  };
  const wallethandleClose = () => {
    setWalletButton(null);
  };

  const userhandleClick = (event) => {
    setUserButton(event.currentTarget);
  };
  const userhandleClose = () => {
    setUserButton(null);
  };

  const notificationhandleClick = (event) => {
    setNotificationButton(event.currentTarget);
  };
  const notificationhandleClose = () => {
    setNotificationButton(null);
  };

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  const menuToggler = () => {
    document.body.classList.toggle('toggle_menu');
    let owl = document.querySelector(".owl-carousel");
  };

  const dispatch = useDispatch();
  const pageInfo = useSelector(pageInfoData);

  useEffect(() => {
    dispatch(handleMainData());
  }, []);

  return (
    <div className="header_wrapper">
      <button className="menu_toggler" onClick={menuToggler}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className="header_menus_wrapper">
        <div className="header_inner">
          <div className="header_logo">
            <Link to={isAuthenticated() ? '/casino/home' : '/'}>
              <img className="desktop_logo" src="/images/Logo_1 1.png" alt="logo" />
            </Link>
          </div>

          {isAuthenticated() ? (
            <>
              <div className="wallet_dropdown">
                <Button
                  onClick={wallethandleClick}
                  size="small"
                  className="dropdownbtn"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <span className="text-muted" style={{ fontSize: '12px' }}>
                    USD
                  </span>{' '}
                  {pageInfo ? pageInfo?.money : 0}
                </Button>
                <CommonModal
                  title={t('Wallet')}
                  modalClassname="wallet_modal"
                  custumClassname={'wallet_button desktop_logo'}
                  triggerText={t('Wallet')}
                  icon={<Icons.Wallet />}
                  btnicon={<Icons.Vault />}
                  modaltitle="Wallet"
                >
                  <WalletModal />
                </CommonModal>
              </div>

              <div className="icon_btn_wrapper">
                <div className="user_popover">
                  <IconButton
                    onClick={userhandleClick}
                    size="small"
                    aria-controls={open1 ? 'account-menu1' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? 'true' : undefined}
                  >
                    <Avatar
                      className="avtar_icon"
                      sx={{ width: 32, height: 32 }}
                    >
                      <Icons.User />
                    </Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={userButton}
                    id="account-menu1"
                    className="user_popover"
                    open={open1}
                    onClose={userhandleClose}
                    onClick={userhandleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={handleLogout}>
                      <Avatar className="inner_avtar_icon" /> {t('logout')}
                    </MenuItem>
                  </Menu>
                </div>
                <div className="notification_btn">
                  <IconButton
                    onClick={notificationhandleClick}
                    size="small"
                    aria-controls={open2 ? 'account-menu2' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? 'true' : undefined}
                  >
                    <Avatar
                      className="avtar_icon"
                      sx={{ width: 32, height: 32 }}
                    >
                      <Icons.Bell />
                    </Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={notificationButton}
                    id="account-menu2"
                    className="user_popover"
                    open={open2}
                    onClose={notificationhandleClose}
                    onClick={notificationhandleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={notificationhandleClose}>
                      {t('empty_notification')}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </>
          ) : (
            <div className="auth-compo">
              <div className="sign-In ">
                <CommonModal
                  modalClassname="authModal"
                  title="Sign In"
                  custumClassname="sign_in_btn"
                  triggerText={t('signin')}
                  className="SignBtn"
                >
                  <Login />
                </CommonModal>
              </div>
              <div className="Register ">
                <CommonModal
                  modalClassname="authModal"
                  title="Register"
                  custumClassname="register_btn"
                  triggerText={t('register')}
                >
                  <Signup />
                </CommonModal>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
