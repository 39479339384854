import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { AuthProvider } from 'react-auth-kit';
import { ToastContainer } from 'react-toastify';

import AppRoutes from './AppRoutes';

function App() {
  const lang = Cookies.get('lang');

  if (lang === undefined) {
    Cookies.set('lang', 'en');
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider
        authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === 'https:'}
      >
        <div className="main_wrapper scrollY scrollX">
          <div className="content_wrapper">
            <ToastContainer />
            <AppRoutes />
          </div>
        </div>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
