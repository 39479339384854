import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import './common_modal.css';

const GameModal = ({ show, onHide, children }) => {
  return (
    <Modal show={show} onHide={onHide} className="custom-modal">
      <Modal.Header className="modal-header1">
        <button type="button" className="close" onClick={onHide}>
          <span>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="modal-body">{children}</Modal.Body>
    </Modal>
  );
};

GameModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default GameModal;
