import React, { lazy } from 'react';
import { RequireAuth } from 'react-auth-kit';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Index from './pages/Index';
import AboutUs from './pages/other/about_page/aboutUs';
import Policy from './pages/other/policy_page/policy';
import ResponsibleGames from './pages/other/responsible_games_page/responsible_games';
import TermCondition from './pages/other/term_condition/term_condition';
import SlotsGameList from './pages/casino/gameTab/SlotsGameList';


const CasinoHome = lazy(() => import('./pages/casino/CasinoHome'));
const SlotCasino = lazy(() => import('./pages/casino/gameTab/SlotCasino'));
const LiveCasino = lazy(() => import('./pages/casino/gameTab/LiveCasino'));
const SlotGame = lazy(() => import('./pages/casino/gameTab/SlotGame'));
const Slots = lazy(() => import('./pages/casino/gameTab/Slots'))



const BettingHistory = lazy(() =>
  import('./pages/casino/history/betting/BettingHistory')
);
const DepositHistory = lazy(() =>
  import('./pages/casino/history/deposit/DepositHistory')
);
const WithdrawHistory = lazy(() =>
  import('./pages/casino/history/withdraw/WithdrawHistory')
);
const ShowGames = lazy(() => import('./pages/casino/ShowGames'));
const Home = lazy(() => import('./pages/home/Home'));
const NoPage = lazy(() => import('./pages/other/errors/404'));
const Events = lazy(() => import('./pages/sidebar/events/Events'));

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}>
          {/* Index Page */}
          <Route index path="/" element={<Home />} />
          <Route
            path="/casino/home"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <CasinoHome />
              </RequireAuth>
            }
          />

          {/* Footer Pages */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-condition" element={<TermCondition />} />
          <Route path="/privecy-policy" element={<Policy />} /> 
          <Route path="/responsible-games" element={<ResponsibleGames />} />

          {/* Games */}
          <Route path="/slot-game" element={<SlotGame />} />
          <Route path="/slots" element={<Slots />} />
          <Route path="/live-casino" element={<LiveCasino />} />
          <Route path="/slot-casino" element={<SlotCasino />} />
          
          <Route
            path="/play/:gameType/:id"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <ShowGames />
              </RequireAuth>
            }
          />
           <Route
            path="/slots-game-list/:gameType/:id"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <SlotsGameList />
              </RequireAuth>
            }
          />
          
          {/* History */}
          <Route
            path="/casino/bet-history"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <BettingHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/casino/deposit-history"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <DepositHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/casino/withdrawal-history"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <WithdrawHistory />
              </RequireAuth>
            }
          />

          {/* Other Pages */}
          <Route
            index
            path="/events"
            element={
              <RequireAuth loginPath={'/notfound'}>
                <Events />
              </RequireAuth>
            }
          />

          {/* 404 */}
          <Route path="/notfound" element={<NoPage />} />
          {/* Default Page */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
