import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../auth.css';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoginSocialFacebook } from 'reactjs-social-login';

import Icons from '../../../components/icons';
import CustomButton from '../../../components/ui/button/CustomButton';
import { defaultConfig } from '../../../configs';
import Policy from '../../other/policy_page/policy';
import TermCondition from '../../other/term_condition/term_condition';
import Login from '../login/Login';

const Signup = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSignup, setShowSignup] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    nickname: '',
    email: '',
    password: '',
    bank: '1',
    bankNumber: 1,
    user_level_id: 1,
    withdrawalPassword: '',
    parent_id: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function handleContinueClick(e) {
    e.preventDefault();
    setLoading(true);
    const isValid = validateForm();

    if (isValid) {
      console.log(formData);
      await handleRegister();
    } else {
      console.log('Form validation failed');
      toast.error('Form validation failed');
    }
    setLoading(false);
  }

  const validateForm = () => {
    const newErrors = {};

    const fields = [
      'name',
      'nickname',
      'email',
      'password',
      'withdrawalPassword',
    ];

    fields.forEach((field) => {
      if (!formData[field]?.trim()) {
        newErrors[field] = `This field is required`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  function handleComponentClick(component) {
    const isPrivacyPolicy = component === 'privacyPolicy';
    const isTerms = component === 'terms';
    const isSignup = component === 'signup';

    setShowPrivacyPolicy(isPrivacyPolicy);
    setShowTerms(isTerms);
    setShowSignup(isSignup);
  }

  async function handleRegister() {
    try {
      const response = await axios.post(
        `${defaultConfig.BASE_API_URL}/register/onRegister`,
        formData
      );
      const responseData = response.data;

      if (responseData?.success) {
        setErrors(null);
        toast.success('Register Successfully');

        setFormData({
          name: '',
          nickname: '',
          email: '',
          password: '',
          bank: '1',
          bankNumber: 1,
          user_level_id: 1,
          withdrawalPassword: '',
          parent_id: '',
        });
      } else {
        setErrors(responseData.error.message);
        toast.error(responseData.error.message ?? 'Internal server error.');
      }
    } catch (error) {
      toast.error(
        error.response
          ? 'Username has already been taken!'
          : 'Internal server error.'
      );
      console.error('An error occurred while making the API request:', error);
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      {!showSignup && !showPrivacyPolicy && !showTerms ? (
        <Container className="">
          <Row>
            <Col md={12} lg={6} xl={5} className="mx-auto login-box">
              <h2>{t('Create an Account')}</h2>

              <Form>
                <Form.Group controlId="nickname" className="mt-2">
                  <Form.Label>
                    {t('Name')} <b className="required">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nickname"
                    value={formData?.nickname ?? ''}
                    onChange={handleInputChange}
                    className="input-style"
                  />
                  {errors?.nickname && (
                    <p className="error-message">{errors?.nickname}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="username" className="mt-2">
                  <Form.Label>
                    {t('UserName')} <b className="required">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData?.name ?? ''}
                    onChange={handleInputChange}
                    className="input-style"
                  />
                  {errors?.name && (
                    <p className="error-message">{errors?.name}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="email" className="mt-2">
                  <Form.Label>
                    {t('Email')} <b className="required">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData?.email}
                    onChange={handleInputChange}
                    className="input-style"
                  />
                  {errors?.email && (
                    <p className="error-message">{errors?.email}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="password" className="mt-2">
                  <Form.Label>
                    {t('Password')} <b className="required">*</b>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData?.password ?? ''}
                    onChange={handleInputChange}
                    className="input-style"
                  />
                  {errors?.password && (
                    <p className="error-message">{errors?.password}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="withdrawalPassword" className="mt-2">
                  <Form.Label>
                    {t('Withdraw Password')} <b className="required">*</b>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="withdrawalPassword"
                    value={formData?.withdrawalPassword ?? ''}
                    onChange={handleInputChange}
                    className="input-style"
                  />
                  {errors?.withdrawalPassword && (
                    <p className="error-message">
                      {errors?.withdrawalPassword}
                    </p>
                  )}
                </Form.Group>
              </Form>

              <Row>
                <Col md={6} className="text-center mt-2">
                  <CustomButton
                    loading={loading}
                    disabled={loading}
                    text={t('register')}
                    onClick={handleContinueClick}
                  />
                </Col>
              </Row>

              <Row>
                <div className="or_block">
                  <span>OR</span>
                  <hr />
                </div>
                <div className="social_blog">
                  <Button>
                    <Icons.Google width="25" height="25" />
                  </Button>
                  <Button>
                    <LoginSocialFacebook
                      appId="658796073109565"
                      onResolve={(response) => {
                        console.log('Response: ', response);
                      }}
                      onReject={(error) => {
                        console.log('Error: ', error);
                      }}
                    >
                      <Icons.Facebook width="25" height="25" />
                    </LoginSocialFacebook>
                  </Button>
                  <Button disabled>
                    <Icons.Line2 width="25" height="25" />
                  </Button>
                  <Button disabled>
                    <Icons.TikTok2 width="25" height="25" />
                  </Button>
                </div>
              </Row>

              <Row>
                <Col md={6} className="others mt-2">
                  <p className="py-2">
                    {t('Already have An Account?')}{' '}
                    <Link onClick={() => handleComponentClick('signup')}>
                      {t('signin')}
                    </Link>
                  </p>
                  <p className="py-2">
                    {t('hCaptcha')}{' '}
                    <span>
                      {' '}
                      <Link
                        className="privacy"
                        onClick={() => handleComponentClick('privacyPolicy')}
                      >
                        {t('PrivacyPolicy')}
                      </Link>
                    </span>{' '}
                    {t('And')}{' '}
                    <span>
                      <Link
                        className="privacy"
                        onClick={() => handleComponentClick('terms')}
                      >
                        {t('TermsofService')}
                      </Link>
                    </span>{' '}
                    {t('Apply')}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}
      {showSignup && <Login />}
      {showPrivacyPolicy && <Policy />}
      {showTerms && <TermCondition />}
    </div>
  );
};

export default Signup;
