import axios from 'axios';
import Cookies from 'js-cookie';

import { defaultConfig } from '../configs';

export async function ApiService({
  url,
  data = {},
  method = 'POST',
  isFormdata = false,
  passToken = true,
}) {
  try {
    const authHeader = Cookies.get('_auth') || null;

    let headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'No-Cache',
    };

    if (authHeader) {
      headers = { ...headers, Authorization: `Bearer ${authHeader}` };
    }

    const response = await axios({
      url,
      method,
      baseURL: defaultConfig.BASE_API_URL,
      data,
      headers,
    });

    return response.data || response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if ([401, 410].includes(error?.response?.status)) {
        console.log(error?.response);
      }
      throw error.response?.data?.error || 'Unknown error';
    } else {
      throw error;
    }
  }
}
