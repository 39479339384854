import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { socket_clientdata } from '../../helpers/socketio';

const initialState = {
  pageinfo: null,
  chargedata: null,
};

export const mainSlice = createSlice({
  name: 'maindata',
  initialState,
  reducers: {
    setPageInfo: (state, action) => {
      state.pageinfo = action.payload;
    },
    setChargeInfo: (state, action) => {
      state.chargedata = action.payload;
    },
  },
});

export const { setPageInfo, setChargeInfo } = mainSlice.actions;

export const handleMainData = (data) => (dispatch) => {
  socket_clientdata.on('pageinfo', (response) => {
    if (response) {
      dispatch(setPageInfo(response.pageinfo[0]));
      dispatch(setChargeInfo(response.betlist[0]));
    }
  });

  const intervalId = setInterval(() => {
    const authCookie = Cookies.get('_auth_state');
    const auth = authCookie ? JSON.parse(authCookie) : {};
    const { id, name } = auth;

    const data = {
      userid: id,
      username: name,
      date: new Date().toISOString().slice(0, 10),
      gameCode: 'slot',
      gameType: 23,
      nCurPage: 1,
    };

    socket_clientdata.emit('pageinfo', data);

    // NOTE: currently getting from api
    // socket_clientdata.emit('chargedata', {
    //   userid: data?.userid,
    // });
  }, 5000);

  return () => {
    // Clear the interval when the component is unmounted or when the returned function is called
    clearInterval(intervalId);

    socket_clientdata.off('pageinfo', () => {
      dispatch(setPageInfo(data.pageinfo[0]));
    });

    socket_clientdata.off('chargedata', () => {
      dispatch(setChargeInfo(data.betlist[0]));
    });
  };
};

export const pageInfoData = (state) => state.maindata.pageinfo;
export const chargeInfoData = (state) => state.maindata.chargedata;

export default mainSlice.reducer;
