import React from 'react'
import BigBanner from '../../../components/ui/bigbanner/bigbanner'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const TermCondition = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BigBanner banner_title={t('Terms and Conditions')} image_class="condition_page_banner" />
      <div className="common_pages_section">
        <div className="container">
          <div className="common_info_section">
            <h4>{t('Terms & Conditions')}</h4>
            <p>{t('Conditions_2')}</p>
            <p>{t('Conditions_3')}</p>
            <p>{t('Conditions_4')}</p>
            <p>{t('Conditions_5')}</p>
          </div>
          <div className="cm_accordian_block">
            <Accordion defaultActiveKey="1" className='cm_accordian'>
              <Accordion.Item eventKey={1}>
                <Accordion.Header>1. {t('Modification of the terms of use')}</Accordion.Header>
                <Accordion.Body>
                  <div className="">
                    <p>{t('Conditions_1')}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={2}>
                <Accordion.Header>2. {t('Terms Used & their Definition')}</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian_list">
                    <ol>
                      <li>
                        <p>{t('Conditions_6')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_7')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_8')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_9')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_10')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_11')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_12')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_13')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_14')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_15')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_16')}</p>
                      </li>
                      <li>
                        <p>{t('Conditions_17')}</p>
                      </li>
                    </ol>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={3}>
                <Accordion.Header>3. {t('Acceptance of these terms & conditions')}</Accordion.Header>
                <Accordion.Body>
                  <div className="">
                    <p>{t('Conditions_18')}</p>
                    <p className='mt-3'>{t('Conditions_19')}</p>
                    <div className="accordian_list second">
                      <ol>
                        <li><p> {t('The Rules')}</p></li>
                        <li><p> {t('The Special Terms')}</p></li>
                        <li><p>  {t('Conditions_20')}</p></li>
                      </ol>
                      <p>{t('Conditions_21')}</p>
                      <ol>
                        <li><p>{t('Conditions_22')}</p></li>
                        <li><p>{t('Conditions_23')}</p></li>                        
                      </ol>
                      <p>{t('Conditions_24')}</p>
                      <p>{t('Conditions_25')}</p>
                      <p>{t('Conditions_26')}</p>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={4}>
                <Accordion.Header>4. {t('Customers’ participation at Paradise7')}</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian_list inner_p">
                    <p>4.1. {t('Age Limitation')}</p>
                    <p>{t('Conditions_27')}</p>
                    <p>{t('Conditions_28')}</p>
                    <p>{t('Conditions_29')}</p>
                    <p>4.2. {t('Acknowledgment')}</p>
                    <p>{t('Conditions_30')}</p>
                    <p>4.3. {t('Eligibility')}</p>
                    <p>{t('Conditions_31')}</p>
                    <p>4.4. {t('Website Access and Usage')}</p>
                    <p>{t('Conditions_32')}</p>
                    <p>{t('Conditions_33')}</p>
                    <p>{t('Conditions_34')}</p>
                    <p>{t('Conditions_35')}</p>
                    <p>{t('Conditions_36')}</p>
                    <p>{t('Conditions_37')}</p>
                    <p>4.5. {t('Identification Documentation')}</p>
                    <p>{t('Conditions_38')}</p>
                    <p>{t('Conditions_39')}</p>
                    <p>4.6. {t('Age Verification Policy and Identification')}</p>
                    <p>{t('Conditions_40')}</p>
                    <p>{t('Conditions_41')}</p>
                    <p>4.7. {t('Risk')}</p>
                    <p>{t('Conditions_42')}</p>
                    <p>{t('Acceptable Use')}</p>
                    <p>{t('Conditions_43')}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={5}>
                <Accordion.Header>5. {t('Depositing funds')}</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian_list inner_p">
                    <p>{t('Conditions_44')}</p>
                    <p>{t('Conditions_45')}</p>
                    <p>{t('Conditions_46')}</p>
                    <p>{t('Conditions_47')}</p>
                    <p>{t('Conditions_48')}</p>
                    <p>{t('Conditions_49')}</p>
                    <p>{t('Conditions_50')}</p>
                    <p>{t('Conditions_51')}</p>
                    <p>{t('Conditions_52')}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={6}>
                <Accordion.Header>6. {t('Account Termination and Suspension')}</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian_list second inner_p">
                    <p>{t('Conditions_53')}</p>
                    <p>{t('Conditions_54')}</p>
                    <div className="">
                      <ol>
                        <li><p>{t('Conditions_55')}</p></li>
                        <li><p>{t('Conditions_56')} </p></li>
                        <li><p> {t('Conditions_57')}</p></li>
                        <li><p>{t('Conditions_58')} </p></li>
                        <li><p>{t('Conditions_59')} </p></li>
                        <li><p> {t('Conditions_60')}</p></li>
                        <li><p>{t('Conditions_61')}</p></li>
                        <li><p>{t('Conditions_62')} </p></li>
                        <li><p>{t('Conditions_63')} </p></li>
                        <li><p>{t('Conditions_64')}</p></li>
                        <li><p>{t('Conditions_65')} </p></li>
                      </ol>
                    </div>
                    <p>{t('Conditions_66')}</p>
                    <p>{t('Conditions_67')}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermCondition