import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import './common_modal.css';

import { t } from 'i18next';

function CommonModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="modal-container d-flex align-items-center justify-content-center">
      <Button onClick={handleShow} className={props.custumClassname}>
        <span className="btnicon">{props.btnicon}</span>
        <span className="btn_text"> {props.triggerText}</span>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className={`${props.modalClassname} custom-modal`}
        centered
      >
        <Modal.Header className="">
          <h5>
            {' '}
            <span>{props.icon}</span> {t(props.modaltitle)}
          </h5>
          <button className="close" onClick={handleClose}>
            <span>&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body className="modal-body">{props.children}</Modal.Body>
      </Modal>
    </div>
  );
}

CommonModal.propTypes = {
  btnicon: PropTypes.node,
  triggerText: PropTypes.string,
  custumClassname: PropTypes.string,
  icon: PropTypes.node,
  modaltitle: PropTypes.string,
  children: PropTypes.node,
  modalClassname: PropTypes.string,
};

export default CommonModal;
